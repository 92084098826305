import { Square } from '@mui/icons-material';
import { Checkbox, FormControlLabel } from '@mui/material';
import { gridFilterModelSelector, GridHeaderFilterCellProps, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';

export const dayLabels = ['H', 'K', 'Sze', 'Cs', 'P', 'Szo', 'V'];

export default function DayCheckboxesHeaderFilter(props: GridHeaderFilterCellProps) {
    const { colDef } = props;
    const apiRef = useGridApiContext();
    const filterModel = useGridSelector(apiRef, gridFilterModelSelector);

    const handleChange = (checked: boolean, day: string) => {
        if (checked) {
            apiRef.current.upsertFilterItem({
                id: day,
                operator: 'contains',
                field: colDef.field,
                value: day,
            });
            return;
        }
        apiRef.current.deleteFilterItem({
            id: day,
            operator: 'contains',
            field: colDef.field,
            value: day,
        });
    };

    return (
        <>
            {dayLabels.map(day => (
                <FormControlLabel
                    key={day}
                    sx={{
                        padding: 0,
                        margin: 0,
                        color: filterModel.items.some(d => d.value === day) ? 'white' : '#999',
                        '.MuiFormControlLabel-label': {
                            fontSize: '0.5rem',
                            marginBottom: '-16px',
                            zIndex: '100',
                        },
                    }}
                    control={
                        <Checkbox
                            checkedIcon={<Square />}
                            onChange={e => handleChange(e.target.checked, day)}
                            name={day}
                            sx={{ padding: 0.125, paddingTop: 0, paddingBottom: 0 }}
                        />
                    }
                    label={<strong>{day}</strong>}
                    labelPlacement='top'
                />
            ))}
        </>
    );
}
