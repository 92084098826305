import {Autocomplete, createFilterOptions, IconButton, SvgIconTypeMap, TextField} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, {ReactElement, useState} from 'react';
import {AutocompleteStopItem, LineStop} from '../../@types/line';
import RouteMapModal from './RouteMapModal';
import {LatLngLiteral} from 'leaflet';
import MapIcon from '@mui/icons-material/Map';
import Tooltip from '@mui/material/Tooltip';
import {red} from '@mui/material/colors';

type StopFormProps = {
    deletable: boolean;
    stop: LineStop;
    onChange: (stop: LineStop) => void;
    handleOnBlur: (stop: LineStop) => void;
    editableStops: LineStop[];
    allCrmStops: AutocompleteStopItem[];
    onDelete: (id: string) => void;
    icon: ReactElement<SvgIconTypeMap<{}, 'svg'>>;
};

const filter = createFilterOptions<AutocompleteStopItem>();

type LocationType = {
    city: string;
    name?: string;
};

const getLabelForLocation = (location: LocationType) => {
    return location.name ? location.city + ', ' + location.name : location.city;
};

export default function StopForm({
                                     icon,
                                     deletable,
                                     stop,
                                     onChange,
                                     handleOnBlur,
                                     editableStops,
                                     allCrmStops,
                                     onDelete
                                 }: StopFormProps) {
    const [mapModalOpen, setMapModalOpen] = useState<boolean>(false);

    return (
        <Grid container px={2} spacing={1} mt={0.5} justifyContent={'flex-start'} alignItems={'end'}>
            <Grid xs={'auto'} px={0}>
                <IconButton
                    onClick={() => {
                        if (deletable) {
                            onDelete(stop.ID);
                        }
                    }}
                    sx={{p: 0.5}}>
                    {icon}
                </IconButton>
            </Grid>
            <Grid xs={12} sm={6} md={'auto'}>
                <TextField
                    fullWidth
                    variant={'standard'}
                    label='Időpont'
                    value={stop.DepartureTime}
                    onChange={e => {
                        onChange({
                            ID: stop.ID,
                            Location: stop.Location,
                            DepartureTime: e.target.value,
                            GpsCoordinate: stop.GpsCoordinate,
                            Comment: stop.Comment,
                            Route: stop.Route,
                            Order: stop.Order,
                        });
                    }}
                    onBlur={e => {
                        handleOnBlur({
                            ID: stop.ID,
                            Location: stop.Location,
                            DepartureTime: e.target.value,
                            GpsCoordinate: stop.GpsCoordinate,
                            Comment: stop.Comment,
                            Route: stop.Route,
                            Order: stop.Order,
                        });
                    }}
                    type='time'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300,
                    }}
                />
            </Grid>
            <Grid xs={12} sm={5}>
                <Autocomplete
                    value={stop.Location}
                    title={stop.Location}
                    onChange={(_, newValue) => {
                        if (!newValue) return;

                        if (typeof newValue === 'string') {
                            onChange({
                                ID: stop.ID,
                                Location: newValue,
                                DepartureTime: stop.DepartureTime,
                                GpsCoordinate: stop.GpsCoordinate,
                                Comment: stop.Comment,
                                Route: stop.Route,
                                Order: stop.Order,
                            });
                        }

                        if (typeof newValue === 'object') {
                            onChange({
                                ID: stop.ID,
                                Location: getLabelForLocation(newValue),
                                DepartureTime: stop.DepartureTime,
                                GpsCoordinate: newValue.latitude && newValue.longitude ? newValue.latitude + ',' + newValue.longitude : stop.GpsCoordinate,
                                Comment: stop.Comment,
                                Route: stop.Route,
                                Order: stop.Order,
                            });
                        }
                    }}
                    fullWidth
                    filterOptions={(options, state) => {
                        const filtered = filter(options, state);
                        const {inputValue} = state;
                        const isExisting = options.some(option => inputValue === option.city + ', ' + option.name);
                        const inputValueArray = inputValue.split(',');

                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                city: inputValueArray[0].trim(),
                                name: inputValueArray.length > 1 ? inputValueArray[1].trim() : '',
                            });
                        }
                        return filtered;
                    }}
                    selectOnFocus
                    autoSelect
                    autoSave={stop.Location}
                    handleHomeEndKeys
                    options={allCrmStops}
                    getOptionLabel={option => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        return getLabelForLocation(option);
                    }}
                    renderOption={(props, option) => (
                        <li {...props} key={option.city + ', ' + option.name}>
                            {getLabelForLocation(option)}
                        </li>
                    )}
                    freeSolo
                    disableClearable
                    renderInput={params => (
                        <TextField
                            {...params}
                            label='Megálló'
                            variant='standard'
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <Tooltip title='GPS koordináta'>
                                        <IconButton onClick={() => setMapModalOpen(true)} sx={{p: 0.5}}>
                                            <MapIcon style={{color: stop.GpsCoordinate ? '#42C4AB' : red[500]}}/>
                                        </IconButton>
                                    </Tooltip>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid xs={12} sm={5}>
                <TextField
                    fullWidth
                    variant={'standard'}
                    label='Megjegyzés'
                    value={stop.Comment}
                    onChange={e => {
                        onChange({
                            ID: stop.ID,
                            DepartureTime: stop.DepartureTime,
                            GpsCoordinate: stop.GpsCoordinate,
                            Location: stop.Location,
                            Comment: e.target.value,
                            Route: stop.Route,
                            Order: stop.Order,
                        });
                    }}
                    title={stop.Comment}
                />
            </Grid>

            {mapModalOpen && (
                <RouteMapModal
                    gpsCoordinate={stop.GpsCoordinate}
                    open={mapModalOpen}
                    handleModalOpen={(isOpen: boolean) => setMapModalOpen(isOpen)}
                    onLocationChange={(latlng: LatLngLiteral) => {
                        onChange({
                            ID: stop.ID,
                            Location: stop.Location,
                            DepartureTime: stop.DepartureTime,
                            GpsCoordinate: `${latlng.lat.toString().substring(0, 11)},${latlng.lng.toString().substring(0, 11)}`,
                            Comment: stop.Comment,
                            Route: stop.Route,
                            Order: stop.Order,
                        });
                    }}
                    stops={editableStops}
                />
            )}
        </Grid>
    );
}
