import { Button, CardActions, CardContent, CircularProgress, Modal, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { ConfirmCard } from '../Layout/styles';
import MoveIcon from '@mui/icons-material/SwapHoriz';
import { Forda } from '../../@types/line';

interface ConfirmDragModalProps {
    open: boolean;
    forda: Forda | null;
    onSave: () => void;
    onClose: () => void;
    loading: boolean;
}

export default function ConfirmDragModal({ open, forda, onSave, onClose, loading }: ConfirmDragModalProps) {
    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent sx={{ p: 2 }}>
                    <Typography variant={'h6'} pb={3}>
                        Járat áthelyezés megerősítése
                    </Typography>
                        {forda?.Name ? (
                            <Typography paragraph>
                                A kiválasztott <strong>járatot/járatokat</strong> áthelyezzük <strong>{forda.Name}</strong> Fordába.
                            </Typography>
                        ) : (
                            <Typography paragraph>
                                A kiválasztott <strong>járatot/járatokat</strong> Fordából töröljük.
                            </Typography>
                        )}
                    <Typography paragraph>Biztosan át szeretné helyezni?</Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        size={'small'}
                        color={'info'}
                        onClick={onClose}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        Mégsem
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        size={'small'}
                        sx={{ borderRadius: 50, marginRight: 0.25 }}
                        onClick={onSave}
                        endIcon={loading ? <CircularProgress size={20} color='inherit' /> : <MoveIcon />}
                        disabled={loading}>
                        {loading ? 'Áthelyezés...' : 'Áthelyezés'}
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
