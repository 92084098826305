import { useState } from 'react';
import { gridFilterModelSelector, GridHeaderFilterCellProps, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';
import { TimePicker } from '@mui/x-date-pickers-pro';

export default function TimePickerHeaderFilter(props: GridHeaderFilterCellProps) {
    const { colDef } = props;
    const apiRef = useGridApiContext();
    const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
    const currentFilter = filterModel.items.find(filterItem => filterItem.field === colDef.field);
    const [currentDate, setCurrentDate] = useState<Date | null>(currentFilter ? new Date(currentFilter.value) : null);

    function formatTime(date: Date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}`;
    }

    const handleOnChange = (date: Date | null) => {
        setCurrentDate(date);
        apiRef.current.deleteFilterItem({
            id: colDef.field,
            operator: 'contains',
            field: colDef.field,
        });
        if (date === null || isNaN(date.getTime())) {
            return;
        }
        apiRef.current.upsertFilterItem({
            id: colDef.field,
            field: colDef.field,
            operator: 'contains',
            value: formatTime(date),
        });
    };

    return (
        <TimePicker
            label='Idő'
            value={currentDate}
            onChange={handleOnChange}
            slotProps={{
                textField: {
                    variant: 'standard',
                    sx: {
                        width: '125px',
                    },
                    error: false,
                },
            }}
        />
    );
}
