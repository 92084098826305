export const getErrorMessage = (response: Response) => {
    if (response.status === 401) {
        return 'A munkamenet lejárt, kérjük lépjen be újra!'
    }
    if (response.status === 403) {
        return 'Hozzáférés megtagadva!'
    }
    if (response.status === 409) {
        return 'Az adatok módosultak, frissítse az oldalt!'
    }
    return response.statusText || `${response.status} | Kommunikációs hiba`
}
