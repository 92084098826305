import { Line, Forda } from '../../@types/line';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragIndicator } from '@mui/icons-material';
import { Box, Checkbox } from '@mui/material';
import { useRouteListSelectionContext } from '../../hooks/useRouteListSelectionContext';

type DndItemContextProps = {
    type: string;
    item: Forda | Line;
    name: string;
};

export default function DndItemContext({ type, item, name }: DndItemContextProps) {
    const { lineSelectionStatus, setLineSelectionStatus } = useRouteListSelectionContext();
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: item.ID,
        data: {
            type: type,
            item: item,
        },
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    if (type === 'forda') {
        return (
            <Box className='DndItemContext-cell' ref={setNodeRef} {...attributes} style={style}>
                {name}
            </Box>
        );
    }

    return (
        <Box
            className='DndItemContext-cell'
            ref={setNodeRef}
            {...attributes}
            style={style}>
            <>
                <DragIndicator
                    {...listeners}
                    sx={{
                        verticalAlign: 'middle',
                        cursor: 'grab',
                        transition,
                        transform,
                    }}
                />
                <Checkbox
                    key={item.ID}
                    sx={{ paddingLeft: 0 }}
                    checked={lineSelectionStatus[item.ID] || false}
                    onChange={event => {
                        setLineSelectionStatus({
                            ...lineSelectionStatus,
                            [item.ID]: event.target.checked,
                        });
                    }}
                />
                {name}
            </>
        </Box>
    );
}
