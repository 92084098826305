const leftPad = (number: number) => {
    return number < 10 ? '0' + number : number.toString();
}

const formatDateToYyyyMMdd = (date: Date | null) => {
    if (!date) return "";
    const month = leftPad(date.getMonth() + 1)
    const dayOfMonth = leftPad(date.getDate())
    return `${date.getFullYear()}-${month}-${dayOfMonth}`
}

const formatDateToYyyyMMddhhmmss = (date: Date | null) => {
    if (!date) return "";
    const month = leftPad(date.getMonth() + 1)
    const dayOfMonth = leftPad(date.getDate())
    return `${date.getFullYear()}-${month}-${dayOfMonth} 00:00:00`
}

export {formatDateToYyyyMMdd, formatDateToYyyyMMddhhmmss}