import { Button, CardActions, CardContent, Modal, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ConfirmCard } from '../Layout/styles';

interface CopyConfirmModalProps {
    open: boolean;
    onClose: () => void;
    onCopy: () => void;
}

export default function CopyConfirmModal({ open, onClose, onCopy }: CopyConfirmModalProps) {
    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent>
                    <Typography paragraph variant='h6' ml={2}>Tényleg másolni szeretné a menetrendet?</Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        size={'small'}
                        color={'info'}
                        onClick={() => onClose()}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        Mégsem
                    </Button>
                    <Button variant='contained' size={'small'} sx={{ borderRadius: 50, marginRight: 0.25 }} onClick={() => onCopy()} startIcon={<ContentCopyIcon />}>
                        Másolás
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
