import { Checkbox, FormControlLabel } from '@mui/material';
import { Square } from '@mui/icons-material';

type DayCheckboxesProps = {
    collection: string[];
    handleOnChange?: (checked: boolean, day: string) => void;
};
export const dayLabels = ['H', 'K', 'Sze', 'Cs', 'P', 'Szo', 'V'];

const DayCheckboxes = ({ collection, handleOnChange = () => {} }: DayCheckboxesProps) => {
    return (
        <>
            {dayLabels.map(day => (
                <FormControlLabel
                    key={day}
                    sx={{
                        padding: 0,
                        margin: 0,
                        color: collection.some(d => d === day) ? 'white' : '#999',
                        '.MuiFormControlLabel-label': {
                            fontSize: '0.5rem',
                            marginBottom: '-16px',
                            zIndex: '100',
                        },
                    }}
                    control={
                        <Checkbox
                            checked={collection.some(d => d === day)}
                            onChange={e => handleOnChange(e.target.checked, day)}
                            checkedIcon={<Square />}
                            name={day}
                            sx={{ padding: 0.125, paddingTop: 0, paddingBottom: 0 }}
                        />
                    }
                    label={<strong>{day}</strong>}
                    labelPlacement='top'
                />
            ))}
        </>
    );
};

export default DayCheckboxes;
