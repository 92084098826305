import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ButtonProps, IconButton } from '@mui/material';
import { useRouteListExpandedGroupingRows } from '../../hooks/useRouteListExpandedGroupingRows';

export default function RouteListGridTreeDataGroupingCell({ id, field, rowNode, row }: GridRenderCellParams) {
    const { expandedRows, setExpandedRows } = useRouteListExpandedGroupingRows();
    const isExpanded = expandedRows.hasOwnProperty(row.name);

    const handleClick: ButtonProps['onClick'] = event => {
        if (rowNode.type !== 'group') {
            return;
        }

        const nextExpandedRows = { ...expandedRows };
        isExpanded ? delete nextExpandedRows[row.name] : (nextExpandedRows[row.name] = true);

        setExpandedRows(nextExpandedRows);
    };

    return (
        <IconButton
            size='small'
            onClick={handleClick}
            sx={{
                fontSize: 16,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'action',
                visibility: rowNode.type === 'group' ? 'visible' : 'hidden',
            }}>
            {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
    );
}
