import React, { createContext, useContext, useState } from 'react';
import { ExpandedGroupingRowContextType, RowExpansionStateHolderType } from '../@types/map';

const DefaultExpandedGroupingRowContext = createContext<ExpandedGroupingRowContextType>({
    expandedRows: {},
    setExpandedRows: (rows: RowExpansionStateHolderType) => [],
});

export const useRouteListExpandedGroupingRows = () => useContext(DefaultExpandedGroupingRowContext);

export const RouteListExpandedGroupingRowsProvider = ({ children }: { children: React.ReactNode }) => {
    const [expandedRows, setExpandedRows] = useState<RowExpansionStateHolderType>({});

    return <DefaultExpandedGroupingRowContext.Provider value={{ expandedRows, setExpandedRows }}>{children}</DefaultExpandedGroupingRowContext.Provider>;
};
