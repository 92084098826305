import React, {createContext, useContext, useState} from 'react';
import {LineCheckboxContextType, LineIdIsOnMapType} from '../@types/map';

const LineCheckboxContext = createContext<LineCheckboxContextType>({
    lineSelectionStatus: {},
    setLineSelectionStatus: (idMap: LineIdIsOnMapType) => {},
});

export const useRouteListSelectionContext = () => useContext(LineCheckboxContext);

export const LineCheckboxProvider = ({ children }: { children: React.ReactNode }) => {
    const [lineSelectionStatus, setLineSelectionStatus] = useState<LineIdIsOnMapType>({});

    return <LineCheckboxContext.Provider value={{
        lineSelectionStatus,
        setLineSelectionStatus
    }}>{children}</LineCheckboxContext.Provider>;
};
