import {LatLngLiteral} from "leaflet";

const convCoordinate = (coords: string | undefined): LatLngLiteral => {
    const [lat, lng] = (coords || '46.075884,18.225557').split(',');
    return {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
    };
};

export {convCoordinate}