import {Card, CardContent, Modal, styled} from '@mui/material';
import RouteMap from './RouteMap';
import {useState} from 'react';
import {LatLngLiteral} from 'leaflet';
import RouteMapConfirmModal from './RouteMapConfirmModal';
import {LineStop} from '../../@types/line';
import {convCoordinate} from "../../utils/gps-coord-converter";

const ConfirmCard = styled(Card)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  background-color: white;
  box-shadow: 2px 2px;
  padding-bottom: 14px;
  margin-top: 150px;
`;

interface RouteMapModalProps {
    open: boolean;
    gpsCoordinate: string;
    handleModalOpen: (val: boolean) => void;
    onLocationChange: (latlng: LatLngLiteral) => void;
    stops: LineStop[];
}

export default function RouteMapModal({
                                          open,
                                          gpsCoordinate,
                                          handleModalOpen,
                                          onLocationChange,
                                          stops
                                      }: RouteMapModalProps) {
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const stop = stops.at(-1);
    const [nextLocation, setNextLocation] = useState<LatLngLiteral | undefined>(convCoordinate(gpsCoordinate));
    const [locationToConfirm, setLocationToConfirm] = useState<LatLngLiteral | undefined>();

    if (!stop) {
        return null;
    }

    return (
        <Modal open={open} onClose={() => handleModalOpen(false)}>
            <ConfirmCard>
                <CardContent sx={{p: 2}}>
                    <RouteMap
                        stops={stops}
                        onLocationClick={(latLng: LatLngLiteral) => {
                            setNextLocation(latLng);
                            setConfirmModalOpen(true);
                        }}
                        locationToConfirm={locationToConfirm}
                        storedLocation={gpsCoordinate ? nextLocation : undefined}
                        setLocationToConfirm={setLocationToConfirm}
                        handleClose={() => handleModalOpen(false)}
                    />

                    <RouteMapConfirmModal
                        open={confirmModalOpen}
                        onClose={() => {
                            setNextLocation(convCoordinate(gpsCoordinate));
                            setLocationToConfirm(undefined);
                            setConfirmModalOpen(false);
                        }}
                        onSave={() => {
                            setConfirmModalOpen(false);
                            handleModalOpen(false);
                            setNextLocation(locationToConfirm)
                            setLocationToConfirm(undefined)
                            if (nextLocation) {
                                onLocationChange(nextLocation)
                            }
                        }}
                    />
                </CardContent>
            </ConfirmCard>
        </Modal>
    );
}
