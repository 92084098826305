import { Button, CardActions, CardContent, Modal, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ConfirmCard } from '../Layout/styles';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from 'react-router-dom';

interface ConfirmModalProps {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
}
export default function ConfirmModal({ open, onClose, onSave }: ConfirmModalProps) {
    const navigator = useNavigate();

    const navigateToHome = () => {
        onClose();
        navigator('/');
    };

    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent>
                    <Typography paragraph variant='h6'>
                        Az oldalon nem mentett változások vannak. Biztosan elnavigál az oldalról?
                    </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                        sx={{ borderRadius: 50 }}
                        variant={'contained'}
                        size={'small'}
                        color={'info'}
                        onClick={() => onClose()}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        Mégsem
                    </Button>
                    <Button variant='contained' size={'small'} sx={{ borderRadius: 50 }} onClick={onSave} startIcon={<SaveIcon />}>
                        Mentés és tovább
                    </Button>
                    <Button
                        variant='contained'
                        color={'error'}
                        size={'small'}
                        sx={{ borderRadius: 50 }}
                        onClick={navigateToHome}
                        endIcon={<ArrowForwardIosIcon />}>
                        Tovább mentés nélkül
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
