import {Chip, Stack} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers-pro';

type OutOfScheduleDatesManagerProps = {
    travel: boolean;
    dates: Date[];
    onAddDate: (date: Date, travel: boolean) => void;
    onDeleteDate: (date: Date) => void;
};

export default function OutOfScheduleDatesManager({ travel, dates, onAddDate, onDeleteDate }: OutOfScheduleDatesManagerProps) {
    return (
        <>
            <Stack direction='row' spacing={4} justifyContent={'start'} alignItems={'flex-end'}>
                <DatePicker
                    label={`Új dátum hozzáadása`}
                    onChange={(newValue: Date | null) => {
                        if (newValue) {
                            onAddDate(newValue, travel)
                        }
                    }}
                    slotProps={{
                        textField: {
                            variant: 'standard',
                            sx: {
                                width: '180px',
                            },
                            error: false,
                        },
                    }}
                />
            </Stack>
            <Stack
                direction='row'
                spacing={1}
                mt={2}
                mb={1}
                sx={{
                    flexWrap: 'wrap',
                    gap: '8px',
                    justifyContent: 'flex-start',
                    '& .MuiChip-root': {
                        marginLeft: 0,
                    },
                }}>
                {dates.sort((a,b) => a.getTime() - b.getTime()).map((date, index) => (
                    <Chip
                        key={index}
                        color={travel ? 'primary' : 'error'}
                        label={date.toLocaleDateString('hu-HU', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                        onDelete={() => {
                            onDeleteDate(date);
                        }}
                    />
                ))}
            </Stack>
        </>
    );
}
