import React, {createContext, useContext, useEffect, useState} from 'react';
import {CRMStop} from '../@types/line';
import {useAuth} from './useAuth';
import {useProvideSnackBar} from './useSnackBar';

const WORKER_TRANSPORT_API_URL = process.env.REACT_APP_WORKER_TRANSPORT_API_URL;

const CRMStopsContext = createContext<CRMStop[]>([]);

export const useAllCRMStops = () => useContext(CRMStopsContext);

export const CRMStopsProvider = ({children}: { children: React.ReactNode }) => {
    const [crmStops, setCrmStops] = useState<CRMStop[]>([]);
    const {showError} = useProvideSnackBar();
    const {user} = useAuth();

    useEffect(() => {
        const fetchCRMStops = async () => {
            try {
                const response = await fetch(WORKER_TRANSPORT_API_URL + '/v1/stop/', {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user?.accessToken}`,
                    },
                });

                if (response.ok) {
                    const stops: CRMStop[] = await response.json();

                    const uniques:  Record<string, CRMStop> = stops.reduce((acc: Record<string, CRMStop>, stop) => {
                        const {city, name} = stop
                        const uniqueKey = city + name

                        if (!acc.hasOwnProperty(uniqueKey)) {
                            stop.city = stop.city.replace(/,/, '')
                            acc[uniqueKey] = stop
                        }
                        return acc;
                    }, {})

                    const uniqueArr = Object.values(uniques);

                    uniqueArr
                        .sort((a: CRMStop, b: CRMStop) => {
                            const compareCity = a.city.localeCompare(b.city);
                            const compareName = a.name.localeCompare(b.name);
                            return compareCity || compareName;
                        });

                    setCrmStops(uniqueArr);
                } else {
                    throw new Error('Failed to fetch stops');
                }
            } catch (error: any) {
                showError(`Hiba történt a megállók lekérdezése közben: ${error.message}`);
            }
        };

        fetchCRMStops();
    }, [user]);

    return <CRMStopsContext.Provider value={crmStops}>{children}</CRMStopsContext.Provider>;
};
