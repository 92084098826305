import { Button, CardActions, CardContent, CircularProgress, Modal, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmCard } from '../Layout/styles';

interface DeleteFordaModalProps {
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
    loading: boolean;
}

export default function DeleteFordaModal({ open, onClose, onDelete, loading }: DeleteFordaModalProps) {
    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent sx={{ p: 2 }}>
                    <Typography variant={'h6'} pb={3}>
                        Forda törlés megerősítése
                    </Typography>
                    <Typography paragraph>
                        A <strong>törlés</strong> gombra kattintva a Fordát törölni fogjuk, mely nem visszavonható.
                    </Typography>
                    <Typography paragraph>Tényleg szeretné törölni a Fordát?</Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        size={'small'}
                        color={'info'}
                        onClick={onClose}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        Mégsem
                    </Button>
                    <Button
                        variant='contained'
                        color='error'
                        size={'small'}
                        sx={{ borderRadius: 50, marginRight: 0.25 }}
                        onClick={onDelete}
                        endIcon={loading ? <CircularProgress size={20} color='inherit' /> : <DeleteIcon />}
                        disabled={loading}>
                        {loading ? 'Törlés...' : 'Törlés'}
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
