import {Button, Card, CardActions, CardContent, Modal, styled, Typography} from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import SaveIcon from '@mui/icons-material/Save';

const ConfirmCard = styled(Card)`
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    background-color: white;
    box-shadow: 2px 2px;
    padding-bottom: 14px;

    @media (max-width: 900px) {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translate(0%, 0%);
        width: 100%;
    }
`;

interface RouteMapConfirmModalProps {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
}

export default function RouteMapConfirmModal({ open, onClose, onSave }: RouteMapConfirmModalProps) {

    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent sx={{ p: 2 }}>
                    <Typography variant='h5'>Szeretné menteni a koordinátát?</Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        size={'small'}
                        color={'info'}
                        onClick={() => onClose()}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        mégsem
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        size={'small'}
                        sx={{ borderRadius: 50, marginRight: 0.25 }}
                        onClick={onSave}
                        endIcon={<SaveIcon />}>
                        Mentés
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
