import {SecuredLayout} from '../Layout/SecuredLayout';
import {Box, Button, Fab, IconButton, Stack, Tooltip, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {Forda, Line} from '../../@types/line';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useAuth, useProvideSnackBar} from '../../hooks';
import {
    DataGridPro,
    DataGridProProps,
    getGridStringOperators,
    GridColDef,
    GridHeaderFilterCellProps,
    GridRowsProp,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ErrorBoundary from '../../hooks/errorBoundary';
import DayCheckboxes from '../Common/DayCheckboxes';
import DayCheckboxesHeaderFilter from './DayCheckboxesHeaderFilter';
import {huHU} from '@mui/x-data-grid/locales';
import {StyledDetailsCard} from '../Layout/styles';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers-pro/AdapterDateFns';
import {hu} from 'date-fns/locale';
import TimePickerHeaderFilter from './TimePickerHeaderFilter';
import {useRouteListExpandedGroupingRows} from '../../hooks/useRouteListExpandedGroupingRows';
import RouteListGridTreeDataGroupingCell from './RouteListGridTreeDataGroupingCell';
import {DndContext, DragOverlay} from '@dnd-kit/core';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import FordaModal from './FordaModal';
import DeleteFordaModal from './DeleteFordaModal';
import ConfirmDragModal from './ConfirmDragModal';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import DndItemContext from './DndItemContext';
import {useRouteListSelectionContext} from '../../hooks/useRouteListSelectionContext';

export default function RouteListPage() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const apiRef = useGridApiRef();
    const { showError, showResponseError, showSuccess } = useProvideSnackBar();
    const { expandedRows } = useRouteListExpandedGroupingRows();
    const { lineSelectionStatus, setLineSelectionStatus } = useRouteListSelectionContext();
    const [lines, setLines] = useState<Line[]>([]);
    const [forda, setForda] = useState<Forda[]>([]);
    const [rows, setRows] = useState<GridRowsProp>([]);

    const [fordaModalOpen, setFordaModalOpen] = useState<boolean>(false);
    const [deleteFordaModalOpen, setDeleteFordaModalOpen] = useState<boolean>(false);
    const [confirmDragModalOpen, setConfirmDragModalOpen] = useState<boolean>(false);

    const [deleteFordaLoading, updateDeleteFordaLoading] = useState<boolean>(false);
    const [saveFordaLoading, setSaveFordaLoading] = useState<boolean>(false);
    const [updateLinesFordaLoading, setUpdateLinesFordaLoading] = useState<boolean>(false);

    const [selectedForda, setSelectedForda] = useState<Forda | null>(null);
    const [dndActiveId, setDndActiveId] = useState<Line | null>(null);
    const [dndOverForda, setDndOverForda] = useState<Forda | null>(null);
    const [dndOverLineId, setDndOverLineId] = useState<number | null>(null);

    const groupingColDef: DataGridProProps['groupingColDef'] = {
        headerName: ' ',
        width: 20,
        minWidth: 20,
        maxWidth: 20,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        resizable: false,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: params => <RouteListGridTreeDataGroupingCell {...params} />,
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            width: 120,
            sortable: false,
            cellClassName: 'cell-fit-content',
            renderHeader: () => <strong>Forda név</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
            renderCell: ({ row }) => {
                if (row.type === 'forda') {
                    const name = `${row.name} (${row.lineCount})`;
                    return <DndItemContext type='forda' item={row.forda} name={name} />;
                }
                return <DndItemContext type='line' item={row.line} name={''} />;
            },
        },
        {
            field: 'departuresTime',
            width: 100,
            disableColumnMenu: true,
            renderHeader: () => <strong>Indulás</strong>,
            renderHeaderFilter: (params: GridHeaderFilterCellProps) => {
                return <TimePickerHeaderFilter {...params} />;
            },
        },
        {
            field: 'departures',
            width: 180,
            renderHeader: () => <strong>Indulás helye</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
        },
        {
            field: 'arrivalsTime',
            width: 100,
            disableColumnMenu: true,
            renderHeader: () => <strong>Érkezés</strong>,
            renderHeaderFilter: (params: GridHeaderFilterCellProps) => {
                return <TimePickerHeaderFilter {...params} />;
            },
        },
        {
            field: 'arrivals',
            width: 180,
            renderHeader: () => <strong>Érkezés helye</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
        },
        {
            field: 'trailId',
            width: 150,
            renderHeader: () => <strong>Nyomvonal azonosító</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
        },
        {
            field: 'lineNumber',
            width: 150,
            renderHeader: () => <strong>Járatszám</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
            renderCell: ({ row }) => {
                if (row.type === 'forda') {
                    return null;
                }
                return row.lineNumber ? row.lineNumber : 'Nincs megadva';
            },
        },
        {
            field: 'validityFrom',
            width: 200,
            disableColumnMenu: true,
            filterable: false,
            type: 'dateTime',
            renderHeader: () => <strong>Érvényesség kezdete</strong>,
            valueFormatter: ({ value }) => {
                return value;
            },
            renderCell: ({ row }) => {
                if (row.type === 'forda') {
                    return null;
                }
                return row.validityFrom;
            },
        },
        {
            field: 'validityTo',
            width: 200,
            disableColumnMenu: true,
            filterable: false,
            type: 'dateTime',
            renderHeader: () => <strong>Érvényesség vége</strong>,
            valueFormatter: ({ value }) => {
                return value;
            },
            renderCell: ({ row }) => {
                if (row.type === 'forda') {
                    return null;
                }
                return row.validityTo ? row.validityTo : 'Nincs megadva';
            },
        },
        {
            field: 'travels',
            width: 180,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => <strong>Közlekedik</strong>,
            renderHeaderFilter: (params: GridHeaderFilterCellProps) => {
                return <DayCheckboxesHeaderFilter {...params} />;
            },
            renderCell: ({ row }) => {
                if (row.type === 'forda') {
                    return null;
                }
                return (
                    <Box sx={{ marginTop: 0.125 }}>
                        <DayCheckboxes collection={row.travels} />
                    </Box>
                );
            },
        },
        {
            field: 'details',
            headerName: '',
            width: 120,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: ({ row }) => {
                if (row.type === 'forda') {
                    if (!row.isEditable) return null;
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Tooltip title='Forda szerkesztése' arrow>
                                <IconButton
                                    color='primary'
                                    onClick={() => {
                                        setSelectedForda({ ID: row.forda.ID, Name: row.name });
                                        setFordaModalOpen(true);
                                    }}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Forda törlése' arrow>
                                <IconButton
                                    color='error'
                                    onClick={() => {
                                        setSelectedForda({ ID: row.forda.ID, Name: row.name });
                                        setDeleteFordaModalOpen(true);
                                    }}>
                                    <ClearIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                }
                return (
                    <Box>
                        <Button
                            variant={'contained'}
                            sx={{ borderRadius: '50px' }}
                            onClick={() => navigate(`/${row.line.ID}`)}
                            endIcon={<ArrowForwardIosIcon sx={{ fontSize: '13px' }} />}
                            size={'small'}>
                            Részletek
                        </Button>
                    </Box>
                );
            },
        },
    ];

    const fetchLines = async () => {
        try {
            const response = await fetch('/api/v1/line', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });
            if (response.ok) {
                const existingLines: Line[] = await response.json();
                setLines(existingLines);
                return;
            }
            showResponseError(response);
        } catch (error: any) {
            showError('Hiba történt a járatok lekérdezése közben');
        }
    };

    const handleGetForda = async () => {
        try {
            const response = await fetch('/api/v1/forda', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });
            if (response.ok) {
                const existingForda: Forda[] = await response.json();
                setForda(existingForda);
                return;
            }
            showResponseError(response);
        } catch (error: any) {
            showError('Hiba történt a forda lekérdezése közben');
        }
    };

    useEffect(() => {
        fetchLines();
        handleGetForda();
    }, []);

    const handleSaveForda = async (fordaName: string) => {
        if (saveFordaLoading || fordaName.trim() === '') return;
        setSaveFordaLoading(true);
        try {
            const response = await fetch('/api/v1/forda', {
                method: selectedForda ? 'PUT' : 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    id: selectedForda ? selectedForda.ID : undefined,
                    name: fordaName,
                }),
            });
            if (response.ok) {
                showSuccess(selectedForda ? 'Sikeres szerkesztés' : 'Sikeres mentés');
                setFordaModalOpen(false);
                const forda: Forda = await response.json();
                if (!selectedForda) {
                    setForda(prevForda => [forda, ...prevForda]);
                    return;
                }
                setForda(prevForda => prevForda.map(f => (f.ID === selectedForda.ID ? { ...f, Name: fordaName } : f)));
                return;
            }
            showResponseError(response);
        } catch (error: any) {
            showError('Hiba történt a forda ' + (selectedForda ? 'szerkesztése' : 'létrehozása') + ' közben!');
        } finally {
            setSaveFordaLoading(false);
        }
    };

    const handleDeleteForda = async () => {
        if (deleteFordaLoading || !selectedForda) return;
        updateDeleteFordaLoading(true);
        try {
            const response = await fetch(`/api/v1/forda/${selectedForda.ID}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });
            if (response.ok) {
                showSuccess('Sikeres törlés');
                setForda(prevForda => prevForda.filter(f => f.ID !== selectedForda.ID));
                const updateLines = lines.map(line => {
                    if (line.Forda?.ID === selectedForda.ID) {
                        line.Forda = null;
                        return line;
                    }
                    return line;
                });
                setLines(updateLines);
                setDeleteFordaModalOpen(false);
                setSelectedForda(null);
                return;
            }
            showResponseError(response);
        } catch (error: any) {
            showError(`Hiba történt a forda törlése közben: ${error.message}`);
        } finally {
            updateDeleteFordaLoading(false);
        }
    };

    const handleUpdateLinesForForda = async () => {
        if (updateLinesFordaLoading || !dndOverLineId) return;
        setUpdateLinesFordaLoading(true);
        const selectedLineIds = Object.keys(lineSelectionStatus).filter(lineId => lineSelectionStatus[parseInt(lineId)]);
        const lineIDs = selectedLineIds.length === 0 ? [dndOverLineId] : selectedLineIds.map(id => parseInt(id));
        selectedLineIds.forEach(id => {
            lineSelectionStatus[parseInt(id)] = false;
        });
        setLineSelectionStatus(lineSelectionStatus);
        try {
            const response = await fetch(`/api/v1/forda/lines`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    ID: dndOverForda?.ID,
                    LineIDs: lineIDs,
                }),
            });
            if (response.ok) {
                showSuccess('Sikeres hozzárendelés');
                setConfirmDragModalOpen(false);
                const updateLines = lines.map(line => {
                    if (lineIDs.includes(line.ID)) {
                        line.Forda = dndOverForda;
                        return line;
                    }
                    return line;
                });
                setLines(updateLines);
                setDndOverForda(null);
                setDndOverLineId(null);
                setUpdateLinesFordaLoading(false);
                return;
            }
            showResponseError(response);
        } catch (error: any) {
            showError('Hiba történt a járatok hozzárendelése közben');
        } finally {
            setUpdateLinesFordaLoading(false);
        }
    };

    const createRows = (): GridRowsProp => {
        let rows: GridRowsProp = [];
        const sortedForda = forda.sort((a, b) => a.Name.localeCompare(b.Name));
        sortedForda.forEach(f => {
            const fordaLines = lines.filter(l => l.Forda?.ID === f.ID);
            if (lines.filter(l => l.Forda?.ID === f.ID).length > 0 && fordaLines.length === 0) return;
            const fordaData: { [key: string]: any } = {
                id: 'forda: ' + f.ID,
                type: 'forda',
                name: f.Name,
                forda: f,
                validityFrom: 'nincs megadva',
                validityTo: 'nincs megadva',
                lineCount: fordaLines.length,
                isEditable: true,
                path: f.Name,
            };
            let sortedFordaLines = fordaLines.sort((a, b) => a.LineStops[0].DepartureTime.localeCompare(b.LineStops[0].DepartureTime));
            sortedFordaLines.forEach(line => {
                const lineData: { [key: string]: any } = {
                    id: 'line: ' + line.ID,
                    type: 'line',
                    line: line,
                    departuresTime: line.LineStops[0].DepartureTime,
                    departures: line.LineStops[0].Location,
                    arrivalsTime: line.LineStops[line.LineStops.length - 1].DepartureTime,
                    arrivals: line.LineStops[line.LineStops.length - 1].Location,
                    trailId: line.TrailID,
                    lineNumber: line.LineNumber,
                    validityFrom: line.Validity.From,
                    validityTo: line.Validity.To,
                    travels: line.Transport,
                    path: f.Name + '//' + line.ID,
                };
                rows = rows.concat(lineData);
            });
            rows = rows.concat(fordaData);
        });
        let emptyFordaLines = lines.filter(l => l.Forda === null);
        if (emptyFordaLines.length === 0) return rows;

        const fordaData: { [key: string]: any } = {
            id: 'forda: -1',
            type: 'forda',
            name: 'Nem fordára helyezett járatok',
            forda: { ID: -1, Name: 'Nem fordára helyezett járatok' },
            validityFrom: 'nincs megadva',
            validityTo: 'nincs megadva',
            lineCount: emptyFordaLines.length,
            isEditable: false,
            path: 'Nem fordára helyezett járatok',
        };
        rows = rows.concat(fordaData);

        emptyFordaLines.forEach(line => {
            const lineData: { [key: string]: any } = {
                id: 'line: ' + line.ID,
                type: 'line',
                line: line,
                departuresTime: line.LineStops[0].DepartureTime,
                departures: line.LineStops[0].Location,
                arrivalsTime: line.LineStops[line.LineStops.length - 1].DepartureTime,
                arrivals: line.LineStops[line.LineStops.length - 1].Location,
                trailId: line.TrailID,
                lineNumber: line.LineNumber,
                validityFrom: line.Validity.From,
                validityTo: line.Validity.To,
                travels: line.Transport,
                path: 'Nem fordára helyezett járatok//' + line.ID,
            };
            rows = rows.concat(lineData);
        });
        return rows;
    };

    function adjustDragOverlayPosition({ transform }: any) {
        return {
          ...transform,
          x: transform.x + 20,
          y: transform.y + 10,
        };
      }

    useEffect(() => {
        setRows(createRows());
    }, [lines, forda]);

    return (
        <SecuredLayout>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hu}>
                <Grid container p={2}>
                    <StyledDetailsCard>
                        <Grid container alignItems='center' justifyContent='space-between' p={2}>
                            <Typography variant={'h4'}>Fordák (járatok)</Typography>
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                <Tooltip title='Új forda létrehozás' arrow>
                                    <Fab
                                        color='primary'
                                        onClick={() => {
                                            setSelectedForda(null);
                                            setFordaModalOpen(true);
                                        }}>
                                        <GroupWorkIcon />
                                    </Fab>
                                </Tooltip>
                                <Tooltip title='Új járat létrehozás' arrow>
                                    <Fab color='primary' onClick={() => navigate('/create')}>
                                        <DirectionsBusIcon />
                                    </Fab>
                                </Tooltip>
                            </Box>
                        </Grid>

                        <DndContext
                            onDragStart={e => {
                                const item = e.active.data.current?.item;
                                if (item) {
                                    setDndActiveId(item);
                                }
                            }}
                            onDragEnd={e => {
                                const { active, over } = e;

                                const line = active.data.current?.item;
                                const currentType = over?.data.current?.type;
                                const currentItem = over?.data.current?.item;

                                if (!line || !currentItem) return;
                                if (currentItem.Forda && line.Forda && currentItem.Forda.ID === line.Forda.ID) return;

                                if (currentType === 'forda') {
                                    setDndOverForda(currentItem);
                                    setDndOverLineId(line.ID);
                                }
                                if (currentType === 'line') {
                                    if (!currentItem.Forda && !line.Forda) return;
                                    setDndOverForda(currentItem.Forda);
                                    setDndOverLineId(line.ID);
                                }
                                setConfirmDragModalOpen(true);
                            }}
                            modifiers={[adjustDragOverlayPosition]}
                        >
                            <Stack sx={{ height: 'calc(100vh - 220px)', mt: 1 }}>
                                <ErrorBoundary>
                                    <DataGridPro
                                        treeData
                                        apiRef={apiRef}
                                        columns={columns}
                                        rows={rows}
                                        getTreeDataPath={row => {
                                            return row.path.split('//');
                                        }}
                                        groupingColDef={groupingColDef}
                                        rowHeight={40}
                                        isGroupExpandedByDefault={params => {
                                            return params.groupingKey ? expandedRows.hasOwnProperty(params.groupingKey.toString()) : false;
                                        }}
                                        localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
                                        headerFilters
                                        hideFooter
                                        sx={{
                                            '& .DndItemContext-cell': {
                                                paddingLeft: '0px',
                                                paddingRight: '20px',
                                            },
                                            '& .cell-fit-content': {
                                                minWidth: 'fit-content'
                                            }
                                        }}
                                    />
                                </ErrorBoundary>
                                <DragOverlay dropAnimation={null}>
                                    {dndActiveId ? (
                                        <Typography sx={{ width: 800 }}>
                                            {dndActiveId.LineStops[0].DepartureTime +
                                                '-' +
                                                dndActiveId.LineStops[0].Location +
                                                ' -> ' +
                                                dndActiveId.LineStops[dndActiveId.LineStops.length - 1].DepartureTime +
                                                '-' +
                                                dndActiveId.LineStops[dndActiveId.LineStops.length - 1].Location +
                                                ' (' +
                                                dndActiveId.LineNumber +
                                                ' ' +
                                                dndActiveId.TrailID +
                                                ')'}
                                        </Typography>
                                    ) : null}
                                </DragOverlay>
                            </Stack>
                        </DndContext>
                    </StyledDetailsCard>
                </Grid>
                <FordaModal
                    open={fordaModalOpen}
                    onClose={() => setFordaModalOpen(false)}
                    isSelectedForda={selectedForda ? true : false}
                    saveFordaLoading={saveFordaLoading}
                    initFordaName={selectedForda?.Name || ''}
                    onSave={handleSaveForda}
                />
                <DeleteFordaModal
                    open={deleteFordaModalOpen}
                    onClose={() => setDeleteFordaModalOpen(false)}
                    onDelete={handleDeleteForda}
                    loading={deleteFordaLoading}
                />
                <ConfirmDragModal
                    open={confirmDragModalOpen}
                    forda={dndOverForda}
                    onClose={() => setConfirmDragModalOpen(false)}
                    onSave={handleUpdateLinesForForda}
                    loading={updateLinesFordaLoading}
                />
            </LocalizationProvider>
        </SecuredLayout>
    );
}
