import {MapContainer, Marker, Polyline, TileLayer, useMapEvents} from 'react-leaflet';
import L, { LatLngLiteral } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { decode } from '@googlemaps/polyline-codec';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { LineStop } from '../../@types/line';
import { convCoordinate } from '../../utils/gps-coord-converter';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [13, 46],
});

L.Marker.prototype.options.icon = DefaultIcon;

function LocationMarker({
    location,
    locationToConfirm,
    setLocationToConfirm,
    handleLocationClick,
}: {
    location: LatLngLiteral;
    locationToConfirm: LatLngLiteral | undefined;
    setLocationToConfirm: (latLng: LatLngLiteral) => void;
    handleLocationClick: (latlng: LatLngLiteral) => void;
}) {
    useMapEvents({
        click(e) {
            setLocationToConfirm(e.latlng);
            handleLocationClick(e.latlng);
        },
    });

    const locationToShow = locationToConfirm || location || null;

    return locationToShow ? <Marker position={locationToShow} /> : null;
}

function RouteMap({
    storedLocation,
    locationToConfirm,
    setLocationToConfirm,
    onLocationClick,
    stops,
    handleClose,
}: {
    storedLocation: LatLngLiteral | undefined;
    locationToConfirm: LatLngLiteral | undefined;
    setLocationToConfirm: (latlng: LatLngLiteral) => void;
    onLocationClick: (location: LatLngLiteral) => void;
    stops: LineStop[];
    handleClose: () => void;
}) {
    let initialLocation = storedLocation || convCoordinate(stops[stops.length - 2]?.GpsCoordinate);
    return (
        <div style={{ height: '80vh', width: '80vw' }}>
            <IconButton
                style={{ position: 'absolute', right: '10px', top: '10px', zIndex: 1000 }}
                onClick={handleClose}
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                <CloseIcon style={{ color: 'white' }} />
            </IconButton>
            <MapContainer center={initialLocation} zoom={13} scrollWheelZoom={true} style={{ height: '100%', width: '100%' }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
                <LocationMarker
                    location={initialLocation}
                    locationToConfirm={locationToConfirm}
                    setLocationToConfirm={setLocationToConfirm}
                    handleLocationClick={onLocationClick}
                />
                {stops
                    .filter(stop => !!stop.GpsCoordinate)
                    .map(stop => (
                        <Marker key={stop.ID} position={convCoordinate(stop.GpsCoordinate)} />
                    ))}

                {stops.map((s, idx) => {
                    const points = decode(s.Route, 6);
                    return <Polyline key={idx + '_' + s.ID} positions={points} weight={5} />;
                })}
            </MapContainer>
        </div>
    );
}

export default RouteMap;
